import React from 'react'; 
import { css } from '@emotion/react';
import {
  ExpCard,
  ExpCardLinkWrapper,
  ExpCard__Media,
  ExpCard__Square,
  ExpCard__Addendum,
  ExpCard__Addendum_Main,
  ExpCard__Addendum_Sub,
  ExpCardListItem,
  ExpCard__Square_Inner,
  ExpCard__Title,
} from "./expcard"


export const PeopleCardInner = ({recordId, person}) => {
  return (
    <ExpCardLinkWrapper id={recordId} active="true">
      <ExpCard>
        <ExpCard__Square>
          {person.image && person.image.localFiles !== null ? (
            <ExpCard__Media img={person.image.localFiles}>
              <ExpCard__Square_Inner>
                <ExpCard__Addendum>
                  <ExpCard__Title data={person.name} />
                  <ExpCard__Addendum_Main data={person.profession} />
                  <ExpCard__Addendum_Sub
                    data={
                      person.partner_company
                        ? person.partner_company[0].data.name
                        : person.startup_company
                        ? person.startup_company[0].data.startup
                        : person.custom_company
                        ? person.custom_company
                        : ""
                    }
                  />
                </ExpCard__Addendum>
              </ExpCard__Square_Inner>
            </ExpCard__Media>
          ) : (
            <ExpCard__Square_Inner
              css={css`
                position: absolute;
              `}
            >
              <ExpCard__Addendum>
                <ExpCard__Title data={person.name} />
                <ExpCard__Addendum_Main data={person.profession} />
                <ExpCard__Addendum_Sub
                  data={
                    person.partner_company
                      ? person.partner_company[0].data.name
                      : ""
                  }
                />
              </ExpCard__Addendum>
            </ExpCard__Square_Inner>
          )}
        </ExpCard__Square>
      </ExpCard>
    </ExpCardLinkWrapper>
  )
}

const PeopleCard = ( {data}, ...props ) => {
    let person = undefined;
    data.data === undefined ? (
      person = data
    ) : (
      person = data.data
    )
  return (
    <ExpCardListItem>
      <PeopleCardInner recordId={data.recordId} person={person} />
    </ExpCardListItem>
  )
}

export default PeopleCard;